import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./Gallery.css";


var pData = require("../Component/Item.json");

const Gallery = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6,
      // slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 700 },
      items: 2,
      // slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 700, min: 0 },
      items: 1,
      // slidesToSlide: 1 // optional, default to 1.
    },
  };

  return (
    <div id='gallery'>
         <div id="gallery-heading">
           <h1>Gallery</h1>
         </div>
        
        <div className="carousel-container">
          <Carousel
            responsive={responsive}
            autoPlay={true}
            autoPlaySpeed={2000}
            infinite
            className="car "
          >
            {pData.map((item) => {
              return (
                <div className="carousel-gallery" key={item.id}>
                  <img className="carousel-image"  src={item.itemImg}/>
                 
                </div>
              );
            })}
          </Carousel>
          
        </div>
      </div>
 
  );
};

export default Gallery;
