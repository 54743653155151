import React from "react";
import { FaPhoneAlt, FaWhatsapp } from "react-icons/fa";
import "./FloatingContactoIcons.css"; // Create this CSS file for styling

const FloatingContactoIcons = () => {
  return (
    <div className="floating-contact-icons">
      <a
        href="tel:+918320556516"
        className="contact-icon phone-icon"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaPhoneAlt className="icon" />
      </a>

      <a
        href="https://wa.me/918320556516"
        className="contact-icon whatsapp-icon"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaWhatsapp className="icon" />
      </a>
    </div>
  );
};

export default FloatingContactoIcons;
