import React from 'react';  
import './Menu.css';  

const menuItems = [  
  {  
    category: 'Sandwiches',  
    items: [  
      { name: 'Cheese Sandwich', price: 100 },  
      { name: 'Mayo Sandwich', price: 120 },  
      { name: 'Chilli Cheese', price: 100 },  
      { name: 'Corn Sandwich', price: 100 },  
      { name: 'Cheese & Corn', price: 120 },  
      { name: 'Paneer Sandwich', price: 120 },  
      { name: 'Sp. Sandwich', price: 130 },  
    ],  
  },  
  {  
    category: 'Bun Maska',  
    items: [  
      { name: 'Butter', price: 80 },  
      { name: 'Jam', price: 80 },  
      { name: 'Butter & Jam', price: 90 },  
      { name: 'Chocolate', price: 80 },  
      { name: 'Butter & Chocolate', price: 90 },  
      { name: 'Nutella', price: 90 },  
      { name: 'Butter & Nutella', price: 100 },  
      { name: 'Cheese', price: 90 },  
      { name: 'Butter & Cheese', price: 100 },  
    ],  
  },  
  {  
    category: 'Tea',  
    items: [  
      { name: 'Masala', price: 20 },  
      { name: 'Ginger', price: 20 },  
      { name: 'Pudina', price: 20 },  
      { name: 'Elaichi', price: 20 },  
      { name: 'Indian Masala Tea', price: 20 },  
      { name: 'Ginger & Pudina', price: 20 },  
      { name: 'Pudina & Elaichi', price: 20 },  
      { name: 'Sugar Free', price: 20 },  
    ],  
  },  
  {  
    category: 'Chai Without Milk',  
    items: [  
      { name: 'Green Tea', price: 30 },  
      { name: 'Black Tea', price: 30 },  
      { name: 'Herbal Green Tea', price: 30 },  
    ],  
  },  
  {  
    category: 'Warm Energizers',  
    items: [  
      { name: 'Black Coffee', price: 30 },  
      { name: 'Hot Coffee', price: 40 },  
      { name: 'Hot Cappuccino', price: 70 },  
    ],  
  },  
  {  
    category: 'Maggie',  
    items: [  
      { name: 'Regular Maggie', price: 100 },  
      { name: 'Cheese Maggie', price: 120 },  
      { name: 'Atta Maggie', price: 100 },  
      { name: 'Tadka Maggie', price: 120 },  
      { name: 'Vegetable Maggie', price: 130 },  
      { name: 'Vegetable Cheese Maggie', price: 140 },  
    ],  
  },  
  {  
    category: 'Milk',  
    items: [  
      { name: 'Hot Bournvita', price: 90 },  
      { name: 'Cold Bournvita', price: 90 },  
      { name: 'Hot Haldi Milk', price: 90 },  
      { name: 'Warm Elaichi Milk', price: 120 },  
      { name: 'Warm Saffron Milk', price: 120 },  
      { name: 'Warm Elaichi & Saffron Milk', price: 120 },  
      { name: 'Cold Elaichi & Saffron Milk', price: 120 },  
      { name: 'Hot Chocolate', price: 130 },  
      { name: 'Cold Chocolate', price: 130 },  
    ],  
  },  
  {  
    category: 'Snacks',  
    items: [  
      { name: 'Samosa (2 pcs.)', price: 50 },  
      { name: 'Bun Samosa (2 pcs.)', price: 120 },  
      { name: 'Cheese Bun Samosa (2 pcs.)', price: 120 },  
      { name: 'Dalwada (100gm)', price: 50 },  
      { name: 'Poha', price: 80 },  
      { name: 'Methi Fritters (100gm) Seasonal', price: 50 },  
      { name: 'Potato Fritters (100gm)', price: 50 },  
      { name: 'Sev (usal)', price: 100 },  
      { name: 'Extra Bun', price: 10 },  
    ],  
  },  
  {  
    category: 'Cold Energizers',  
    items: [  
      { name: 'Cold Cappuccino', price: 120 },  
      { name: 'Classic Cold Coffee', price: 120 },  
      { name: 'Vanilla Cold Coffee', price: 140 },  
      { name: 'Hazelnut Cold Coffee', price: 150 },  
    ],  
  },  
];  

const Menu = () => {  
  return (  
    <div className="menu-container">  
      {menuItems.map((category) => (  
        <div key={category.category} className="menu-category">  
          <h2>{category.category}</h2>  
          <div className="menu-items">  
            {category.items.map((item) => (  
              <div key={item.name} className="menu-item">  
                <div className="item-details">  
                  <h3>{item.name}</h3>  
                  <p>₹{item.price}</p>  
                </div>  
              </div>  
            ))}  
          </div>  
        </div>  
      ))}  
    </div>  
  );  
};  

export default Menu;