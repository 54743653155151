import React from 'react';
import './About.css';
import cafe4 from '../Assets/Images/kesricafe2.jpg';

const About = () => {
  return (
    <section id="about" className="about">
    <h1 className='abouth1'>About Kesari Cafe</h1>
      <div className="about-container">
     
        <div>
        <img src={cafe4} alt="Kesri Cafe Interior" className="about-image" /></div>
        <div>
        <p>
          Kesari Cafe is a cozy and welcoming place where you can enjoy delicious food, great ambiance, and friendly service. Our passion for quality ingredients and memorable dining experiences drives us every day.
        </p>
        <p>
          Whether you're here for a quick coffee or a leisurely meal, Kesari Cafe is the perfect spot to relax and savor the moment.
        </p>
        </div>
      </div>
    </section>
  );
};

export default About;
