import React from "react";
import "./Footer.css"; // Importing the stylesheet for styling

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="about-cafe">
          <h3>About Our Cafe</h3>
          <p>
            Welcome to Kesari Cafe, where we serve not only coffee but a warm
            and inviting atmosphere. Enjoy our wide selection of gourmet
            coffees, fresh pastries, and delightful meals crafted with love and
            quality ingredients. Your experience matters to us!
          </p>
        </div>
        <div className="about-cafe">
          <h3>Address</h3>
          <p>
            109, 1st Floor, Darshanam Capital, Canal Ring Road, Bhayli, Vadodara
          </p>
        </div>
      </div>
      <hr></hr>
      <div className="footer-info">
        <p>
          &copy; {new Date().getFullYear()} Kesari Cafe. All rights reserved.
        </p>
        <p className="footer-contact">
          {" "}
          <a href="mailto:info@kesricafe.com">
            Contact us: info@kesricafe.com
          </a>{" "}
          | <a href="tel:+8320556516">(+91) 8320556516</a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
