import React from 'react';  
import './Services.css'; // Importing CSS for styling  

const Services = () => {  
  return (  
    <section id='services' className="services">  
      <h2 className='serviceh2'>Our Services</h2>  
      <div className="services-container">  
        <div className="card">  
          <h3>Catering</h3>  
          <p>We offer catering services for your events.</p>  
        </div>  
        <div className="card">  
          <h3>Wi-Fi</h3>  
          <p>Enjoy free Wi-Fi while you sip your coffee.</p>  
        </div>  
        <div className="card">  
          <h3>Delivery</h3>  
          <p>Get your meals delivered straight to your door.</p>  
        </div>  
        <div className="card">  
          <h3>Takeout</h3>  
          <p>Order your favorites for takeout.</p>  
        </div>  
        <div className="card">  
          <h3>Online Booking</h3>  
          <p>Book your table online easily.</p>  
        </div>  
        <div className="card">  
          <h3>Event Hosting</h3>  
          <p>Host your events with us, tailored to your needs.</p>  
        </div>  
        <div className="card">  
          <h3>Live Music</h3>  
          <p>Enjoy live music events every weekend at our venue.</p>  
        </div>  
        <div className="card">  
          <h3>Cooking Classes</h3>  
          <p>Join our cooking classes and learn from the experts.</p>  
        </div>  
      </div>  
    </section>  
  );  
}  

export default Services;