import React from 'react';
import './Hero.css'
import img from '../Assets/Images/kesricafe1.jpg'
import Header1 from './Header1';

const Hero = () => {
  return (
    <>
    <Header1/>
    <section className="hero1">  
  
</section> 
    
    </>
  );
};

export default Hero;