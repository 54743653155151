import React, { useState } from 'react';  
import './Contect.css'; // Updated filename to correct spelling  

const Contact = () => {  
  const [formData, setFormData] = useState({ name: '', email: '', number: '', message: '' });  

  const handleChange = (e) => {  
    const { name, value } = e.target;  
    setFormData({ ...formData, [name]: value });  
  };  

  const handleSubmit = (e) => {  
    e.preventDefault();  
    // Handle form submission (e.g., send the data to an API)  
    console.log(formData);  
  };  

  return (  
    <section id='contact' className="combined">   
      <div className="location">  
        <h2>Find Us</h2>   
        <div className="map">  
          {/* Google Maps Embed */}  
          <iframe  
            title="Location"  
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3691.729962647708!2d73.12975487354649!3d22.2882167433366!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395fc7f65fdb263b%3A0xaaeeb14147c94cd4!2sDarshanam%20Capital%20-%20Shops%2C%20Offices%20%26%20Showrooms%20for%20Sale%20in%20Vadodara!5e0!3m2!1sen!2sin!4v1728291520564!5m2!1sen!2sin"  
            className="map-iframe"  
            allowFullScreen=""  
            loading="lazy"  
          ></iframe>  
        </div>  
      </div>  

      <div className="contact">  
        <h2>Contact Us</h2>  
        <form onSubmit={handleSubmit}>  
          <input type="text" name="name" placeholder="Your Name" onChange={handleChange} required />  
          <input type="email" name="email" placeholder="Your Email" onChange={handleChange} required />  
          <input type="tel" name="number" placeholder="Your Number" onChange={handleChange} required />  
          <textarea name="message" placeholder="Your Message" onChange={handleChange} required />  
          <button type="submit">Send Inquiry</button>  
        </form>  
      </div>  
    </section>  
  );  
};  

export default Contact;