import React from 'react';  
import './Bakery.css'; // Import the CSS file for styling  

const Bakery = () => {  
    return (  
        <div className="bakery">  
            
           
            <section className="services">  
                
                <div className="service">  
                    <h3>Breakfast</h3>  
                    <p>Start your day with a delicious breakfast.</p>  
                </div>  
                <div className="service">  
                    <h3>Lunch</h3>  
                    <p>Enjoy a hearty lunch.</p>  
                </div>  
                <div className="service">  
                    <h3>Coffee</h3>  
                    <p>Freshly brewed coffee.</p>  
                </div>  
                <div className="service">  
                    <h3>Bakery</h3>  
                    <p>Baked fresh daily.</p>  
                </div>  
            </section>  
          
            
        </div>  
    );  
};  

export default Bakery;