import React from "react";

import "./App.css";
import About from "./Component/About";

import Services from "./Component/Services";

import Contact from "./Component/Contact";

import Gallery from "./Component/Gallery";
import Footer from "./Component/Footer";

import Hero from "./Component/Hero";
import Gallery2 from "./Component/Gallery2";
import Bakery from "./Component/Bakery";
import FloatingContactoIcons from "./Component/FloatingContactoIcons";
import Menu from "./Component/Menu";

const App = () => {
  return (
    <>
    
      <Hero />
      <About />
      <Gallery />
      <Menu />
      <Gallery2 />

      <Services />
      <Bakery />

      <Contact />

      <FloatingContactoIcons />
      <Footer />
    </>
  );
};

export default App;
