
import React, { useRef, useState } from "react";
import {
    FaBars,
    FaTimes,
  } from "react-icons/fa";
import logo from '../Assets/Images/logo-01.png'
import './Header1.css'
const Header1 = () => {
    const [isOpen, setIsOpen] = useState(false); // Use state to handle navbar toggle

  const navbarRef = useRef(null);
  return (
    <>
    <nav className="navbar" ref={navbarRef}>
    <div className="navbar-brand">
      <img  src={logo} alt="Logo"></img>

    </div>

    <div className="navbar-toggle" onClick={() => setIsOpen(!isOpen)}>
      {isOpen ? <FaTimes /> : <FaBars />}
    </div>
    <ul className={`navbar-links ${isOpen ? "open" : ""}`}>
      <li>
        <a href="#about">About Us</a>
      </li>
      <li>
        <a href="#gallery">Gallery</a>
      </li>
      <li>
        <a href="#services">Services</a>
      </li>
      <li>
        <a href="#contect">Contect-us</a>
      </li>
    </ul>
  </nav>
    </>
  )
}

export default Header1
