import React from 'react';  
import './Gallery2.css'; // Ensure you have a separate CSS file for styling  




const Gallery2 = () => {  
 

  return (  
    <div className="gallery2">
   <h1>Popular Items</h1>
    <div  className='wrapper'> 
 
      <div className='card card1'></div>  
      <div className='card card2'></div>  
      <div className='card card3'></div>  
      <div className='card card4'></div>  
    </div> 
    </div> 
  );  
};  

export default Gallery2;